<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item>园区企业用工需求</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">用工需求</div>
      </div>
      <el-button type="primary" @click="add" >发布职位</el-button>
      <div class="table-box">
        <el-table v-loading="queryTable.loading" :data="queryTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号" width="50"> </el-table-column>
          <!-- <el-table-column prop="corporateInformation.count" label="职位数量" align="center"></el-table-column> -->
          <el-table-column prop="corporateInformation.submitTime" label="提交时间" align="center"></el-table-column>
          <el-table-column label="职位状态" :formatter="formatterState" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="look(scope.row.corporateInformation.id)" style="color: #135694" type="text">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row style="margin-top: 25px">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :page-size="queryTable.pageSize"
          :total="queryTable.count"
          :current-page.sync="queryTable.page"
          @current-change="queryTable.search()"
        ></el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import { QueryTable } from '@/assets/js/QueryTable';

export default {
  name: 'policyService',
  data() {
    return {
      state: [
        {
          value: 1,
          label: '已报名',
        },
        {
          value: 2,
          label: '未报名',
        },
        {
          value: 3,
          label: '不可报名',
        },
      ],
      queryTable: new QueryTable('corporateinfo', {}),
    };
  },
  methods: {
    add() {
      this.$router.push({ name: 'StaffNeedDetail', params: { id: 0 } });
    },
    look(id) {
      this.$router.push({ name: 'StaffNeedDetail', params: { id } });
    },
    formatterState(row) {
      let label;
      this.state.forEach((element) => {
        if (element.value === row.corporateInformation.positionStatus) {
          label = element.label;
        }
      });
      return label;
    },
  },
  created() {
    // this.$api.getcorporateinfoList().then((res) => {
    //   this.table = res.rows;
    // });
    this.queryTable.search()
  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .table-box {
      margin-top: 20px;
    }
  }
}
</style>
